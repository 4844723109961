/* Built In Imports */
import { useEffect, useRef } from 'react';

/* External Imports */
import { ChakraProvider } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';

/* Internal Imports */
/* Components */
import BuildTimeComment from '@components/Utility/BuildTimeComment';
import ILALayout from '@ila/components/Layout/Layout';
import AuthContextProvider from '@store/auth-context';
import { BUILD_TYPE_CONFIG } from '@utils/index';
import { ishaTheme } from '../styles/theme/index';

/* Services */
/* Styles */

const nextBuildType = process?.env?.NEXT_PUBLIC_BUILDTYPE?.toUpperCase();
const { buildType } = BUILD_TYPE_CONFIG;

if (nextBuildType === buildType.DATOCMS_ILA) {
  await import('../styles/globals-ila.css');
}

/**
 * @param Component
 * @param pageProps
 */

const ISOApp = ({ Component, pageProps }) => {
  const firstUpdate = useRef(true);

  const tagManagerArgs = {
    gtmId: 'GTM-5G2NJ8P',
  };

  useEffect(() => {
    setTimeout(function () {
      if (firstUpdate.current) {
        if (process.browser) {
          TagManager.initialize(tagManagerArgs);
        }
        firstUpdate.current = false;
      } else {
        // consoleLog('GTM pushed to data layer', window.location.href);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Pageview',
          url: window.location.href,
          title: window.document.title,
        });
      }
    }, 1500);
  }, [pageProps]);

  // console.log('app conditions', pageProps?.menuType, nextBuildType);

  return (
    <AuthContextProvider>
      <ChakraProvider resetCSS theme={ishaTheme}>
        <ILALayout>
          <Component {...pageProps} />
        </ILALayout>
      </ChakraProvider>
      <BuildTimeComment />
    </AuthContextProvider>
  );
};

export default ISOApp;
